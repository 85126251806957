import React from 'react';
import Select from 'react-select';
import getText from '../../utils/language';
import packageJson from '../../../package.json';

import './Footer.scss';

import logo from './img/logo.svg';
import iconTwitter from './img/icon-twitter.png';
import iconFacebook from './img/icon-facebook.png';
import iconTelegram from './img/icon-telegram.png';

const FooterComponent = (props) => {
	return (
		<footer className={props.footerOpen ? "open" : ""}>
			<div className={"container-wrapper" + (props.navOpen ? " nav-opened" : "")}>
				<div className="container">
					<div className="content-footer">
						<div className="left">
							<Select
								value={props.selectedOption}
								onChange={props.handleChangeSelect}
								options={props.options}
								isSearchable={false}
								isClearable={false}
								classNamePrefix="select-custom"
								className="select-custom"
							/>
							<p>&copy;FootballCoin {new Date().getFullYear()}. {getText('GENERAL_ALL_RIGHTS')}. {`${getText('version').capitalize()}: ${packageJson.version}.`}</p>
						</div>

						<div className="right">
							<div className="app">
								<img src={logo} alt="" />
								<a href="#">Android&ensp;</a>
								<a href="">/&ensp;App Store</a>
							</div>
							<ul className="social-list">
								<li><a href="https://twitter.com/xfcfootballcoin/" title="Twitter" target="_blank"><img src={iconTwitter} alt="Twitter" /></a></li>
								<li><a href="https://www.facebook.com/xfcfootballcoin/" title="Facebook" target="_blank"><img src={iconFacebook} alt="Facebook" /></a></li>
								<li><a href="https://t.me/joinchat/HIik3wz4MX_r1Qxnc4P0GA" title="Telegram" target="_blank"><img src={iconTelegram} alt="Telegram" /></a></li>
							</ul>
							<ul className="footer-menu">
								<li><a href="/" title={getText('MENU_DASHBOARD')}>{getText('MENU_DASHBOARD')}</a></li>
								<li><a href="https://www.footballcoin.io/terms-of-use/" title={getText('GENERAL_TERMS')} target="_blank">{getText('GENERAL_TERMS')}</a></li>
								<li><a href="https://www.footballcoin.io/privacy-policy/" title={getText('GENERAL_PRIVACY_POLICY')} target="_blank">{getText('GENERAL_PRIVACY_POLICY')}</a></li>
								<li><a href="https://www.footballcoin.io/contact/" title={getText('CONTACT_TEAM')} target="_blank">{getText('CONTACT_TEAM')}</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default FooterComponent;
