import React from 'react';
import { Link } from 'react-router-dom';

import { hexToRgba } from "../misc";

import './Avatar.scss';

import defaultAvatar from './img/default-avatar.svg';
import noop from 'AppUtils/noop';
import {prefixImg} from '../../utils/image';

const AvatarComponent = (props) => {
    let borderSize;
    props.border_size ?
        borderSize = props.border_size
        :
        borderSize = "1";
    return (
        <div
            className="avatar"
            style={{
              border: `${props.border_color ? borderSize + "px" : "0"} solid ${props.border_color ? props.border_color : "transparent"}`
            }}
        >
            <Link to={props.link ? props.link : '#'} onClick={props.onClick ? (e) => props.onClick(e) : noop}>
                <div
                    className="image"
                    style={{
                        width: `${props.size}px`,
                        height: `${props.size}px`,
                        backgroundImage: `url(${props.is_more || !props.image ? defaultAvatar : !props.noCdn ? prefixImg(props.image, `${props.size}x0`, false, true) : props.image})`
                    }}
                >
                </div>
                <div
                    className="overlay"
                    style={{backgroundColor: `${props.overlay_color ? hexToRgba(props.overlay_color, .6) : null}`}}
                >
                    {props.is_more_text ?
                        <p>{props.is_more_text}</p>
                        : null
                    }
                </div>
            </Link>
        </div>
    );
};

export default AvatarComponent;
