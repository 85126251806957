import './Error.scss';

import React, { PureComponent } from 'react';
import { string, func } from 'prop-types';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';

import getText from 'AppUtils/language';

const mapStateToProps = state => ({
	pathname: state.router.location.pathname
});


export default
@connect(mapStateToProps, { goBack })
class ErrorBoundary extends PureComponent {
	static propTypes = {
		pathname: string,
		goBack: func,
	};

	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};
	}

	static getDerivedStateFromProps(props, state) {
		const { pathname } = props;
		const { hasError, location } = state;
		if (hasError && location && pathname !== location) {
			return {
				hasError: false,
			}
		}
		return null;
	}

	componentDidCatch(error) {
		// TODO: Maybe log errors ?
		const { pathname } = this.props;
		this.setState({ location: pathname });
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	render() {
		const { hasError } = this.state;
		if (hasError) {
			return (
				<div className="Error-container container-wrapper" style={{ paddingTop: 60 }}>
					<h1><strong>{getText('Oops, there was an error')}</strong></h1>
					<button
						className={'btn btn-primary btn-lg btn-block'}
						onClick={ () => {
							this.setState({ hasError: false });
							this.props.goBack();
						}}
					>
						<strong>{getText('Go back and try again')}</strong>
					</button>
				</div>
			);
		}

		return this.props.children;
	}
}
