import React from "react";

import logo from "./img/logo.svg";
import iconTwitter from "./img/icon-twitter.png";
import iconFacebook from "./img/icon-facebook.png";
import iconTelegram from "./img/icon-telegram.png";

import "./Footer.scss";

const FooterSimpleComponent = () => {
	return (
		<footer className="footer-simple">
			<div className="container-wrapper">
				<div className="container">
					<div className="content-footer">
						<div className="left">
							<p>
								&copy;FootballCoin {new Date().getFullYear()}.{" "}
								{getText("GENERAL_ALL_RIGHTS")}
							</p>
						</div>

						<div className="right">
							<div className="app">
								<img src={logo} alt="" />
								<a href="#">Android&ensp;</a>
								<a href="">/&ensp;App Store</a>
							</div>
							<ul className="social-list">
								<li>
									<a href="#">
										<img src={iconTwitter} alt="" />
									</a>
								</li>
								<li>
									<a href="#">
										<img src={iconFacebook} alt="" />
									</a>
								</li>
								<li>
									<a href="#">
										<img src={iconTelegram} alt="" />
									</a>
								</li>
							</ul>
							<ul className="footer-menu">
								<li>
									<a href="#">{getText("MENU_DASHBOARD")}</a>
								</li>
								<li>
									<a href="#">{getText("GENERAL_TERMS")}</a>
								</li>
								<li>
									<a href="#">{getText("GENERAL_PRIVACY_POLICY")}</a>
								</li>
								<li>
									<a href="#">{getText("CONTACT_TEAM")}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default FooterSimpleComponent;
