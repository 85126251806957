import{put,fork,race,take,call,takeLatest,cancel,cancelled,select,delay}from"redux-saga/effects";import{LOCATION_CHANGE}from"connected-react-router";import ls from"local-storage";import moment from"moment";import*as Sentry from"@sentry/react";import{catchError,retryWhen,delay as RxJSDelay,take as RxJSTake}from"rxjs/operators";import{of,EMPTY}from"rxjs";import{getQueryVariables,removeQueryVariable}from"AppUtils/url";import{getValue}from"AppUtils/objects";import*as AUTH from"./types";import{apiGet}from"AppUtils/api";import{authHasTwoSteps}from"./selectors";import*as accountActions from"../../../my-account/main/store/actions";let token;function*onSet(action){const newToken=action.payload.token;let language=getValue(action,"payload.user.userDetails.languageId.short_code","");if(newToken){token=newToken;ls.set("token",token);}if(language){setLang(language);}else{language=window.navigator.userLanguage||window.navigator.language;setLang(language.substr(0,2));}}function*onReset(){token=null;ls.set("token","");}function setLang(lang){ls.set("lang",lang);}function*onCheck(){const quickLoginData=yield call(checkQuickLogin);if(quickLoginData&&quickLoginData.token&&quickLoginData.user){yield postQuickLogin();yield put({type:AUTH.AUTH_SET,payload:{token:quickLoginData.token,user:quickLoginData.user}});}else{const token=yield call(getToken);if(token){// Make a request and get my user info
const response=yield apiGet("/v3/auths/").pipe(catchError(error=>{console.error("An error occurred:",error);return EMPTY;})).toPromise();if(typeof response!=="undefined"){if(response&&response.error==="SUCCESS"&&response.params){const user=response.params;Sentry.setUser({id:user.id,username:user.nickname,email:user.email});yield put({type:AUTH.AUTH_SET,payload:{token:user.session_key?user.session_key:token,user,hasTwoSteps:user.has_two_steps}});}else if(response&&response.error==="SUCCESS"&&!response.params&&token){yield put({type:AUTH.AUTH_SET,payload:{token,user:{}}});}else{yield put({type:AUTH.AUTH_RESET});}}}else{yield put({type:AUTH.AUTH_RESET});}}}function*checkQuickLogin(){const queryParams=getQueryVariables(window.location.search);if(queryParams.qlogin){// Make a request and get my user info
return yield apiGet("/v3/user",{token:queryParams.qlogin}).pipe(catchError(()=>EMPTY)).toPromise();}}function*postQuickLogin(){yield put({type:UI.UI_REPLACE,payload:{to:{pathname:window.location.pathname,search:removeQueryVariable(window.location.search,"qlogin"),hash:window.location.hash},options:{keephash:true}}});}function*onLogout(){yield put({type:AUTH.AUTH_RESET});Sentry.configureScope(scope=>scope.setUser(null));}function*authUpdater(){const checker=function*(){try{const ttl=1000*60*__CONFIG__.WEBAPP.DATA_REFRESH.AUTH_UPDATE;// Check the auth each x minutes
while(true){const token=yield call(getToken);const hasTwoSteps=yield select(authHasTwoSteps);if(!token){yield put({type:AUTH.AUTH_RESET});break;}//get more details about the user (according to Catalin)
// , {}, { jwtoken: token }
const response=yield apiGet("/v3/auths/1?now=".concat(Date.now())).pipe(catchError(()=>EMPTY)).toPromise();if(response&&response.error==="SUCCESS"&&response.params){const user=response.params;yield put({type:AUTH.AUTH_UPDATE,payload:{token:user.session_key?user.session_key:token,user}});}else if(!hasTwoSteps){yield put({type:AUTH.AUTH_RESET});}yield delay(ttl);}yield cancel(proc);}finally{if(yield cancelled()){}}};const proc=yield fork(checker);if(yield take(AUTH.AUTH_RESET)){yield cancel(proc);}}function*fiatUpdater(){const checker=function*(){try{const ttl=1000*60*__CONFIG__.WEBAPP.DATA_REFRESH.FIAT_UPDATE;// Check the auth each x minutes
let xfcConfig="";let msg="";while(true){const response=yield apiGet("/v2/fiat").pipe(catchError(()=>EMPTY)).toPromise();if(response&&response.error==="SUCCESS"&&response.params){xfcConfig=response.params;}else if(getValue(response,"errors")){msg=response.errors[0];}else{msg=403;}yield put(accountActions.profileSetXfcConfig({xfcConfig,msg}));yield delay(ttl);}yield cancel(proc);}finally{if(yield cancelled()){}}};const proc=yield fork(checker);if(yield take(AUTH.AUTH_RESET)){yield cancel(proc);}}function*notificationsUpdater(){const checker=function*(){try{const ttl=1000*60*__CONFIG__.WEBAPP.DATA_REFRESH.NOTIFICATIONS_UPDATE;// Check the auth each x minutes
while(true){const response=yield apiGet("/v3/user/notifications_summary/").pipe(retryWhen(errors=>errors.pipe(RxJSDelay(1000),RxJSTake(3))),catchError(()=>of([]))).toPromise();if(response&&response.error==="SUCCESS"&&response.params){yield put(accountActions.profileSetNotificationSummary({data:response.params,loading:false}));}else{yield put(accountActions.profileSetNotificationSummary({data:{},loading:false}));}yield delay(ttl);}yield cancel(proc);}finally{if(yield cancelled()){}}};const proc=yield fork(checker);if(yield take(AUTH.AUTH_RESET)){yield cancel(proc);}}function*garbageCollector(){const checker=function*(){try{const ttl=1000*60*__CONFIG__.WEBAPP.DATA_REFRESH.GARBAGE_COLLECTOR;// Check the auth each x minutes
while(true){//clean up past contests from local storage
/* IOAN ROBCIUC
				logger.info('gc');
				const setTeam = ls.get("setTeam");

				if (setTeam && Object.keys(setTeam).length !== 0 && setTeam.constructor === Object) {
					for (let property in setTeam) {
						let team = setTeam[property];
						if (team && team.expire && moment() > moment(team.expire)) {
							delete setTeam[property];
							ls.set("setTeam", setTeam);
						}
					}
				}
				*/yield delay(ttl);}yield cancel(proc);}finally{if(yield cancelled()){}}};const proc=yield fork(checker);if(yield take(AUTH.AUTH_RESET)){yield cancel(proc);}}function*authReload(){const token=yield call(getToken);if(!token){yield put({type:AUTH.AUTH_RESET});}const response=yield apiGet("/v3/auths/1?now=".concat(Date.now())).pipe(catchError(()=>EMPTY)).toPromise();if(response&&response.error==="SUCCESS"&&response.params){const user=response.params;yield put({type:AUTH.AUTH_UPDATE,payload:{token:user.session_key?user.session_key:token,user}});}}function getToken(){try{return ls.get("token");}catch(e){return"";}}function*initRestrictions(){// Watch for auth/login and then check the routes
let user=null;let pathname=window.location.pathname;let hash=window.location.hash;while(true){// Wait for the auth_check to issue set or reset
const result=yield race({set:take(AUTH.AUTH_SET),reset:take(AUTH.AUTH_RESET),location:take(LOCATION_CHANGE)});if(result.location){pathname=getValue(result.location,"payload.pathname","unknown");hash=getValue(result.location,"payload.hash","unknown");}else if(result.set){user=result.set.payload.user;}else if(result.reset){user=null;}}}function*accountUpdater(){while(true){// Wait for the auth_check to issue set or reset
const _yield$race=yield race({set:take(AUTH.AUTH_SET),reset:take(AUTH.AUTH_RESET)}),set=_yield$race.set,reset=_yield$race.reset;}}function*watchInitialize(){yield takeLatest(AUTH.AUTH_SET,onSet);yield takeLatest(AUTH.AUTH_SET,authUpdater);yield takeLatest(AUTH.AUTH_SET,fiatUpdater);yield takeLatest(AUTH.AUTH_SET,notificationsUpdater);yield takeLatest(AUTH.AUTH_SET,garbageCollector);yield takeLatest(AUTH.AUTH_RELOAD,authReload);yield takeLatest(AUTH.AUTH_RESET,onReset);yield takeLatest(AUTH.AUTH_CHECK,onCheck);yield takeLatest(AUTH.AUTH_LOGOUT,onLogout);}export default function*sagas(){yield fork(initRestrictions);yield fork(accountUpdater);yield fork(watchInitialize);}