const host=__CONFIG__.CDN.HOST;import canUseWebp from'./canUseWebp';// documentation:
// https://thumbor.readthedocs.io/en/latest/index.html
// https://thumbor.readthedocs.io/en/latest/imaging.html
// example:
// https://game.footballcoin.io/thumbs/fit-in/x400/smart/filters:contrast(5):quality(65):format(webp)/00/00/Daftlad1985.png
// https://game.footballcoin.io/thumbs/fit-in/x400/smart/filters:contrast(5):quality(65):format(webp)/new-assets/04/fe/tb80-Buendia-Emiliano-min.png
// export const prefixCardImg = (imgOrgPath, size = 'x200', filters = 'filters:quality(80):format(webp):grayscale()') => {
// 	if (!host) {
// 		return imgOrgPath;
// 	}
//
// 	const resourcePath = imgOrgPath.replace('https://game.footballcoin.io/new-assets/', '');
//
// 	return `${host}/thumbs/fit-in/${size}/smart/${filters ? `${filters}/` : ``}${resourcePath}`;
// };
let supportWebp=canUseWebp();export const prefixImg=function(imgOrgPath){let size=arguments.length>1&&arguments[1]!==undefined?arguments[1]:'';let filters=arguments.length>2&&arguments[2]!==undefined?arguments[2]:"filters:quality(100):format(".concat(supportWebp?'webp':'png',")");let disableFilters=arguments.length>3?arguments[3]:undefined;if(!host){return imgOrgPath;}const resourcePath=imgOrgPath?imgOrgPath.replace("".concat(__CONFIG__.API.HOST,"/new-assets/"),''):'';if(disableFilters){return"".concat(host,"/thumbs/fit-in/").concat(size,"/").concat(resourcePath);}return"".concat(host,"/thumbs/fit-in/").concat(size,"/smart/").concat(filters?"".concat(filters,"/"):"").concat(resourcePath);};