import './Button.scss';
import noop from 'lodash-es/noop';

import React from 'react';

const ButtonComponent = (props) => {

  return (
		<div
			className="btn"
			style={{
				paddingLeft: props.padding_side + "px",
				paddingRight: props.padding_side + "px",
				backgroundColor: props.bg_color,
				color: props.text_color,
				marginBottom: props.margin_bottom + "px",
				marginTop: props.margin_top + "px",
			}}
			onClick={props.handleClick || noop}
		>
			<p>
				{props.children}
				{props.has_mobile_border ? (
					<span
						className="btn-mobile-border"
						style={{
							backgroundColor: props.mobile_border_color,
						}}
					/>
				) : null}
			</p>
		</div>
	);
};

export default ButtonComponent;
