import*as COMMON from'../store/types';import{getValue}from"AppUtils/objects";import{getCookie}from"AppUtils/cookies";let __store;//it's useful to force showing XXXXXX to easily detect what's not wrapper for translations
const __fakeValue=getCookie('__APP_LANG_FAKE_VALUES','');//Store as a map all objects
let __repository={global:{Example:'example'}};const __missing={};export const middleware=store=>next=>action=>{const result=next(action);if(action.type===COMMON.APP_SET_TRANSLATIONS){__repository.global=action.payload.__store;}return result;};middleware.register=_store=>{__store=_store;};const getText=function(label){let group=arguments.length>1&&arguments[1]!==undefined?arguments[1]:'global';let labelFiltered=generateKey(label);let val=getValue(__repository,"".concat(group,".").concat(labelFiltered),null);if(val){return val;}return __fakeValue||label;};/**
 * Returns a hashed form for longer phrases
 * Find, match and chat with people nearby => Find, match and chat wit1786646157
 * @param text
 * @returns {*}
 */function generateKey(text){if(text){text=text.replace(/[\W]+/g,"_");//replaces all alphanumeric except _
}if(text&&text.length>48){return text.substr(0,16)+hashCode(text);}return text;}/**
 * Creates a quick hash for texts.
 * @param text
 * @returns {number}
 */function hashCode(text){let hash=0;if(text.length==0)return hash;for(let i=0;i<text.length;i++){const char=text.charCodeAt(i);hash=(hash<<5)-hash+char;hash&=hash;// Convert to 32bit integer
}return hash;}export default getText;