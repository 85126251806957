import React, { Fragment } from 'react';

import Header from './header/HeaderContainer';
import Footer from './footer/FooterContainer';
import FooterSimple from './footer/FooterSimpleComponent';
import Menu from './menu/MenuContainer';
import ErrorBoundary from 'Common/errors/GenericErrorComponent';

const LayoutComponent = (props) => {
    return (
        <Fragment>
            {
              props.uiSection === "auth" ?
                null
                :
                <Header navOpen={props.navOpen} />
            }
            {
              props.uiSection === "auth" ?
                null
                :
                <Menu uiSection={props.uiSection} navOpen={props.navOpen} openNav={props.openNav} closeNav={props.closeNav} footerOpen={props.footerOpen} toggleFooter={props.toggleFooter} />
            }
            <ErrorBoundary>
                {props.children}
            </ErrorBoundary>
            {
              props.uiSection === "auth" ?
                <FooterSimple />
              :
                <Footer navOpen={props.navOpen} footerOpen={props.footerOpen} />
            }
        </Fragment>
    );
};

export default LayoutComponent;
