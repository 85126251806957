import React from 'react';
import {Link} from 'react-router-dom';

import iconNotification from './img/icon-notification.svg';

import './NotificationCard.scss';

const NotificationCardComponent = (props) => {

	const { notification, closeNotification, markAsRead } = props;

	return (
		<div className="notification-card-component">
			<div className="container-wrapper">
				<div className="container">
					<div className="notification-card">
						<p className="notification-card-title"><strong dangerouslySetInnerHTML={{ __html: notification.message_title }} /> <img src={iconNotification} alt=""/></p>
						<p dangerouslySetInnerHTML={{ __html: notification.description }} />
						<ul className="actions">
							<li><Link to={`/my-account/notification/${notification.id}`} onClick={() => closeNotification()}>Details</Link></li>
							<li>
								<ul className="sub-actions">
									<li><Link to="/" onClick={(e) => {e.preventDefault(); markAsRead()}}>Mark as read</Link></li>
									<li><Link to="/" onClick={(e) => {e.preventDefault(); closeNotification()}}>Close</Link></li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NotificationCardComponent;
