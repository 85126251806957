const packageJson=require('../package.json');// This optional code is used to register a service worker.
// register() is not called by default.
// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.
// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA
const isLocalhost=Boolean(window.location.hostname==='localhost'||// [::1] is the IPv6 localhost address.
window.location.hostname==='[::1]'||// 127.0.0.1/8 is considered localhost for IPv4.
window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));export const register=config=>{if('serviceWorker'in navigator){// const BASE_URL = __CONFIG__.APP.URL;
// The URL constructor is available in all browsers that support SW.
// const publicUrl = new URL(BASE_URL, window.location.href);
// if (publicUrl.origin !== window.location.origin) {
// Our service worker won't work if PUBLIC_URL is on a different origin
// from what our page is served on. This might happen if a CDN is used to
// serve assets; see https://github.com/facebook/create-react-app/issues/2374
// return;
// }
const swUrl="".concat(window.location.origin,"/sw.js");if(isLocalhost){// This is running on localhost. Let's check if a service worker still exists or not.
checkValidServiceWorker(swUrl,config);// Add some additional logging to localhost, pointing developers to the
// service worker/PWA documentation.
navigator.serviceWorker.ready.then(()=>{console.log('[PWA]. This web app is being served cache-first by a service '+'worker. To learn more, visit https://bit.ly/CRA-PWA');});}else{// Is not localhost. Just register service worker
registerValidSW(swUrl,config);}/* IOAN ROBCIUC
		resetCacheNamesIfTheVersionIsHigher();
		*/}};const registerValidSW=async(swUrl,config)=>{const _await$import=await import('workbox-window'),Workbox=_await$import.Workbox;const wb=new Workbox(swUrl);wb.addEventListener('waiting',event=>{console.log("\n\t\t\t[PWA]. A new service worker has installed, but it can't activate\n\t\t\tuntil all tabs running the current version have fully unloaded.\n\t\t");});wb.addEventListener('activated',event=>{// `event.isUpdate` will be true if another version of the service
// worker was controlling the page when this version was registered.
if(!event.isUpdate){console.info('[PWA]. Service worker activated for the first time!');// If your service worker is configured to precache assets, those
// assets should all be available now.
}});wb.addEventListener('message',event=>{if(event.data.type==='CACHE_UPDATED'){const updatedURL=event.data.payload.updatedURL;console.info("[PWA]. A newer version of ".concat(updatedURL," is available!"));}});wb.register();};function checkValidServiceWorker(swUrl,config){// Check if the service worker can be found. If it can't reload the page.
fetch(swUrl).then(response=>{// Ensure service worker exists, and that we really are getting a JS file.
const contentType=response.headers.get('content-type');if(response.status===404||contentType!=null&&contentType.indexOf('javascript')===-1){// No service worker found. Probably a different app. Reload the page.
navigator.serviceWorker.ready.then(registration=>{registration.unregister().then(()=>{window.location.reload();});});}else{// Service worker found. Proceed as normal.
registerValidSW(swUrl,config);}}).catch(()=>{console.info('[PWA]. No internet connection found. App is running in offline mode.');});}/* IOAN ROBCIUC
function resetCacheNamesIfTheVersionIsHigher() {
	const currentVersion = packageJson.version.split('.').join('');
	const localStorageVersion = localStorage.getItem('version');

	if (localStorageVersion === null || currentVersion > localStorageVersion) {
		localStorage.setItem('version', currentVersion);

		caches.keys().then(function (cacheNames) {
			return Promise.all(
				cacheNames
					.filter(function (cacheName) {
						return true;
					})
					.map(function (cacheName) {
						return caches.delete(cacheName);
					}),
			)
		});

		navigator.serviceWorker.ready.then(registration => {
			registration.unregister().then(() => {
				window.location.reload();
			});
		});
	}
}
*/