import{put,fork,takeLatest,takeEvery}from'redux-saga/effects';import moment from'moment';import ls from'local-storage';import{catchError,retryWhen,delay as RxJSDelay,take as RxJSTake}from'rxjs/operators';import{of}from'rxjs';import{getValue}from'AppUtils/objects';import{apiGet,apiPost}from"AppUtils/api";import playHomeSagas from'../play-home/main/store/sagas';import playContestDetailsSagas from'../play-contest-details/main/store/sagas';import playTeamSagas from'../play-create-team/main/store/sagas';import playCreateContestSagas from'../play-create-contest/main/store/sagas';import*as PLAY from'./types';import*as PLAYHOME from'../play-home/main/store/types';/* START LEAGUES */function*onLoadLeagues(action){const initial=getValue(action,'payload.initial',true);const name=getValue(action,'payload.name',0);const onlyNational=getValue(action,'payload.onlyNational')?"/".concat(action.payload.onlyNational):'';const acrossAll=getValue(action,'payload.acrossAll',false);const retry=initial?5:1;const leagues=[];const response=yield apiGet("/frontutils/leagues/".concat(name).concat(onlyNational)).pipe(retryWhen(errors=>errors.pipe(RxJSDelay(1000),RxJSTake(retry))),catchError(()=>of([]))).toPromise();const defaultSort=[];if(response&&response.error==='SUCCESS'&&response.params){if(acrossAll){leagues.push({value:0,name:getText('All leagues'),label:getText('All leagues'),seasonId:0},{value:-1,name:getText('FILTER_ACCROSS_EU'),label:getText('FILTER_ACCROSS_EU'),seasonId:-1});}else{leagues.push({value:-1,name:getText('All leagues'),label:getText('All leagues'),seasonId:-1});}response.params.forEach(item=>{item.label="".concat(item.name," ").concat(getValue(item,'countryId.country_code')?"(".concat(getValue(item,'countryId.country_code').toUpperCase(),")"):''),item.value=item.id;item.seasonId=item.season?item.season.id:item.id;defaultSort.push(item.id);leagues.push(item);});}//if this is the initial call or the leagues are set
if(initial||leagues)yield put({type:PLAY.PLAY_LEAGUES_SET,payload:{list:leagues,defaultSort}});}function*leaguesWatchInitialize(){yield takeLatest(PLAY.PLAY_LEAGUES_LOAD,onLoadLeagues);yield takeLatest(PLAY.PLAY_TEAMS_LOAD,onLoadTeams);}/* END LEAGUES */ /* START TEAMS */function*onLoadTeams(action){const query=getValue(action,'payload.query');const leagueId=getValue(action,'payload.leagueId')&&action.payload.leagueId!=-1?"/".concat(action.payload.leagueId):'';const retry=3;let teams='';const response=yield apiGet("/v3/market/team_search/".concat(query).concat(leagueId)).pipe(retryWhen(errors=>errors.pipe(RxJSDelay(1000),RxJSTake(retry))),catchError(()=>of([]))).toPromise();const defaultSort=[];if(response&&response.error==='SUCCESS'&&response.params){teams=response.params;}yield put({type:PLAY.PLAY_TEAMS_SET,payload:{teams}});}function*teamsWatchInitialize(){yield takeLatest(PLAY.PLAY_TEAMS_LOAD,onLoadTeams);}/* END TEAMS */ /* START CONTESTS */function*onLoadContests(action){let filters=getValue(action,'payload.filters',{});const noItems=getValue(action,'payload.noItems');const loadType=getValue(action,'payload.loadType');const currentPage=getValue(action,'payload.currentPage',1);const scrollDirection=getValue(action,'payload.scrollDirection','SCROLL_DOWN');let contestConfig=ls.get('contestConfig');//purify filters so that API will love them
filters=sanitizeFilters(Object.assign({},filters));const response=yield apiPost("/v4/contests/list/".concat(currentPage).concat(noItems?"/".concat(noItems):''),{...filters}).pipe(retryWhen(errors=>errors.pipe(RxJSDelay(1000),RxJSTake(5))),catchError(()=>of([]))).toPromise();const defaultSort=[];const contests=[];let summary={};if(response&&response.error==='SUCCESS'&&response.params){response.params.data.forEach((item,idx)=>{defaultSort.push(item.id);if(item.contest_dt){// adjust contest_dt to user time and subtract 10 min
let startDate=moment.utc(item.contest_dt).local();item.contest_dt_adj=startDate;let joinDate=moment.utc(item.contest_dt).local().subtract(contestConfig?contestConfig.join_time:5,"minutes");item.contest_join_adj=joinDate;let viewTeamDate=moment.utc(item.contest_dt).local().add(contestConfig?contestConfig.join_time:5,"minutes");item.contest_view_team=viewTeamDate;}if(item.contest_end_dt){// adjust contest_end_dt to user time
let endDate=moment.utc(item.contest_end_dt).local();item.contest_end_dt_adj=endDate;}contests.push(item);});summary=response.params.summary;}if(PLAYHOME.PLAY_SET_NO_CONTESTS_UPCOMING_EASY==loadType){yield put({type:PLAYHOME.PLAY_SET_NO_CONTESTS_UPCOMING_EASY,payload:{summary,list:contests||[],filters,defaultSort}});}else if(PLAYHOME.PLAY_SET_NO_CONTESTS_UPCOMING_PRO==loadType){yield put({type:PLAYHOME.PLAY_SET_NO_CONTESTS_UPCOMING_PRO,payload:{summary,list:contests||[],filters,defaultSort}});}else{yield put({type:PLAY.PLAY_CONTESTS_SET,payload:{summary,list:contests||[],filters,defaultSort,scrollDirection}});}}function sanitizeFilters(filters){if(Object.keys(filters).length!==0&&filters.constructor===Object){for(let property in filters){if(filters[property]===''||property==='league_id'&&filters[property]=='-1'){delete filters[property];}if(filters[property]===false||filters[property]==='false'||filters[property]===true||filters[property]==='true'){if(filters[property]===true||filters[property]==='true'){filters[property]=1;}else{delete filters[property];}}if(property==='sort_how'&&!isNaN(filters[property])){filters[property]=filters[property]==1?'asc':'desc';}// `all leagues` option has value -1 in frontutils API: /api/frontutils/rounds/-1
if(property==='league_id'&&filters[property]===-1){delete filters[property];}if(property==='is_private'){if(filters[property]==='all'){// delete filters['free_entry'];
delete filters['is_private'];}else if(filters[property]==='free_entry'){filters['free_entry']=1;delete filters['is_private'];}else if(filters[property]==='is_private'){filters['is_private']=1;delete filters['free_entry'];}}// special
if(Array.isArray(filters[property])){const filtersArr=filters[property];delete filters[property];filtersArr.map((item,idx)=>{filters["".concat(property,"[").concat(idx,"]")]=item;});}}}return filters;}function*contentsWatchInitialize(){yield takeLatest(PLAY.PLAY_CONTESTS_LOAD,onLoadContests);}/* END CONTESTS */ /* START CONTEST TEAM */function*onLoadContestTeam(action){const contestId=getValue(action,'payload.contestId');const teamId=getValue(action,'payload.teamId');const showPoints=getValue(action,'payload.showPoints')?"/".concat(action.payload.showPoints):'';const retry=3;let team='';let msg='';const response=yield apiGet("/v4/contests/userTeam/".concat(contestId,"/").concat(teamId).concat(showPoints)).pipe(retryWhen(errors=>errors.pipe(RxJSDelay(1000),RxJSTake(retry))),catchError(()=>of([]))).toPromise();if(response&&response.error==='SUCCESS'&&response.params){team=response.params;}else if(response.errors){msg=response.errors[0];}yield put({type:PLAY.PLAY_SET_CONTEST_TEAM,payload:{team,msg}});}function*onLoadVirtualContestPlayers(action){// sort_how (asc, desc)
// sort_by (name, points)
// search
// player_type (1-4)
// team_id
let filters=getValue(action,'payload.filters',{});const contestId=getValue(action,'payload.contestId');const page=getValue(action,'payload.page',1);const perPage=getValue(action,'payload.perPage',30);const retry=3;let virtualContestPlayers='';let msg='';const response=yield apiPost("/v4/contests/contest_players/".concat(contestId,"/").concat(page,"/").concat(perPage),{...filters}).pipe(retryWhen(errors=>errors.pipe(RxJSDelay(1000),RxJSTake(retry))),catchError(()=>of([]))).toPromise();if(response&&response.error==='SUCCESS'&&response.params){virtualContestPlayers=response.params;}else if(response.errors){msg=response.errors[0];}yield put({type:PLAY.PLAY_SET_VIRTUAL_CONTEST_PLAYERS,payload:{virtualContestPlayers,msg}});}function*contestTeamWatchInitialize(){yield takeEvery(PLAY.PLAY_LOAD_CONTEST_TEAM,onLoadContestTeam);yield takeEvery(PLAY.PLAY_LOAD_VIRTUAL_CONTEST_PLAYERS,onLoadVirtualContestPlayers);}/* END CONTEST TEAM */export default function*sagas(){yield fork(contestTeamWatchInitialize);yield fork(leaguesWatchInitialize);yield fork(teamsWatchInitialize);yield fork(contentsWatchInitialize);yield fork(playHomeSagas);yield fork(playContestDetailsSagas);yield fork(playTeamSagas);yield fork(playCreateContestSagas);}