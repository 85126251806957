import React from 'react';

import './Dropdown.scss';

const DropdownComponent = (props) => {

	return (
		<div className={`dropdown${props.dropdownIsOpen ? ' active' : ''}`}>
			<ul>
				{props.children}
			</ul>
		</div>
	)
};

export default DropdownComponent;
